import React from 'react';
import StandardLayout from '../../layouts/standard';
import moment from 'moment';
import ReactMarkdown from 'react-markdown';

const EventItem = props => {
    const {
        name,
        from,
        to,
        location: loc,
        type,
        description,
        showTime,
        image
    } = props;

    return <div className={"w-100 relative shadowed pa2 event-card mb4 " + type}>
         <div class="uk-inline uk-width-1-1">
            <img src={image.publicURL} className="uk-width-1-1" />
            {/* <div class="uk-overlay-primary uk-position-cover"></div>
            <div class="uk-overlay uk-position-bottom uk-light">     
                <div className="w-100 event-content">
                    <h1 className="b white">{name}</h1>
                    <div className="divider" style={{ width: '200px' }} />
                    <div className="mv3">
                        {to && <span className="f4">From: </span>}
                        <h3 className="mv0 white">{moment(from).format('MMMM DD YYYY ' + (showTime ? 'h:mma' : ''))}</h3>

                        {to && <div className="mv4">
                            <span className="f4">To: </span>
                            <h3 className="mv0 white">{moment(to).format('MMMM DD YYYY ' + (showTime ? 'h:mma' : ''))}</h3>
                        </div>}
                    </div>
                    {loc && <div className="mv3">
                        <span className="f4">At:</span>
                        <p className="mv0 white"><ReactMarkdown>{loc}</ReactMarkdown></p>
                    </div>}

                    {description && <div>
                        <p>{description}</p>
                    </div>}
                </div>
            </div>*/}
        </div>
        {/* <div className="w-100 h-100 overflow-hidden flex items-center justify-center event-image">
            <img src={image.publicURL} />
        </div> */}
    </div>
}

export default function EventsPage(props) {
    const { events } = require('../../data_file.json');

    return <StandardLayout title="Events">
        <section className="uk-section">
            <div className="uk-container">
                <h1 className="color-primary">Events</h1>
                
                <div data-uk-filter="target: #event-cards">

                    <div className="flex">
                        <button className="uk-button uk-button-default" data-uk-filter-control=".domestic">Domestic</button>
                        <button className="uk-button uk-button-default" data-uk-filter-control=".international">International</button>
                    </div>

                    <div className="mv4" id="event-cards">
                        {events.map(event => <EventItem {...event} />)}
                    </div>
                </div>
            </div>
        </section>
    </StandardLayout>
}
